"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTheme = exports.HTML_THEME_ATTRIBUTE = exports.LIGHT_THEME_KEY = exports.DARK_THEME_KEY = exports.DEFAULT_LIGHT_THEME = exports.DEFAULT_DARK_THEME = void 0;
exports.DEFAULT_DARK_THEME = 'dark';
exports.DEFAULT_LIGHT_THEME = 'default';
exports.DARK_THEME_KEY = 'dark';
exports.LIGHT_THEME_KEY = 'light';
exports.HTML_THEME_ATTRIBUTE = 'data-theme';
/**
 * Gets the theme based on config and current data-theme of the HTML.
 *
 * @param html The HTML element of the page.
 * @param config The configuration for this chart.
 */

function getTheme(html, config) {
  var _a, _b, _c, _d, _e;

  var htmlTheme = (_a = html.getAttribute(exports.HTML_THEME_ATTRIBUTE)) !== null && _a !== void 0 ? _a : exports.LIGHT_THEME_KEY;

  if (!(htmlTheme === exports.LIGHT_THEME_KEY || htmlTheme === exports.DARK_THEME_KEY)) {
    htmlTheme = exports.LIGHT_THEME_KEY;
  }

  var defaultTheme = htmlTheme === exports.LIGHT_THEME_KEY ? exports.DEFAULT_LIGHT_THEME : exports.DEFAULT_DARK_THEME;
  return (_e = (_c = (_b = config === null || config === void 0 ? void 0 : config.theme) === null || _b === void 0 ? void 0 : _b[htmlTheme]) !== null && _c !== void 0 ? _c : (_d = config === null || config === void 0 ? void 0 : config.mermaid) === null || _d === void 0 ? void 0 : _d.theme) !== null && _e !== void 0 ? _e : defaultTheme;
}

exports.getTheme = getTheme;