
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import book from "../themes/book"
import { Chart1 } from "./components/chart1.jsx"
import { Chart2 } from "./components/chart2.jsx"
import { Chart3 } from "./components/chart3.jsx"
import { Chart4 } from "./components/chart4.jsx"
import { CodeSurfer } from "code-surfer"
import { dracula } from "@code-surfer/themes"
import { assetUrl } from "../themes/const"
export const theme = { ...book,
  ...dracula
};

const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introduction to basic Arduino programming techniques`}</h1>
    <p><a parentName="p" {...{
        "href": "https://basic.idp.michaellee8.com"
      }}>{`https://basic.idp.michaellee8.com`}</a></p>
    <p>{`Source code: `}<a parentName="p" {...{
        "href": "https://github.com/michaellee8/idp-public-notes"
      }}>{`https://github.com/michaellee8/idp-public-notes`}</a></p>
    <hr></hr>
    <h1>{`Assumptions`}</h1>
    <ul>
      <li parentName="ul">{`Expecting understanding to programming of a yr3 or yr4 CE student or equivalent.`}</li>
      <li parentName="ul">{`Has basic understanding to C++.`}</li>
      <li parentName="ul">{`Has basic concepts to Arduino hardware.`}</li>
      <li parentName="ul">{`Has learnt basic interaction with Arduino sensors.`}</li>
    </ul>
    <hr></hr>
    <h1>{`Topics`}</h1>
    <ul>
      <li parentName="ul">{`Arduino's programming model`}</li>
      <li parentName="ul">{`Basic code organization and conventions`}</li>
      <li parentName="ul">{`Sampling`}</li>
      <li parentName="ul">{`Non-blocking control (no `}<inlineCode parentName="li">{`delay()`}</inlineCode>{`)`}</li>
    </ul>
    <hr></hr>
    <h1>{`Basic vs Advanced`}</h1>
    <p>{`Advanced topic will be discussed at `}<a parentName="p" {...{
        "href": "https://advanced.idp.michaellee8.com"
      }}>{`https://advanced.idp.michaellee8.com`}</a>{` . Lots of very useful but not essential stuffs there.`}</p>
    <ul>
      <li parentName="ul">{`Some suggestions given in basic slides are only good for the scope of this course since the codebase is rather simple, or have limitations.`}</li>
      <li parentName="ul">{`Will have better or more correct way discussed in advanced.`}</li>
      <li parentName="ul">{`Those suggestions will be tagged with ⭐.`}</li>
    </ul>
    <hr></hr>
    <h1>{`Arduino's programming model`}</h1>
    <ul>
      <li parentName="ul">{`Perform setup like initializing sensors, motors and serial in `}<inlineCode parentName="li">{`setup()`}</inlineCode>{` once only.`}</li>
      <li parentName="ul">{`Program in `}<inlineCode parentName="li">{`loop()`}</inlineCode>{` will be executed forever, use it to implement your own program logic and recurring tasks like reading values.`}</li>
      <li parentName="ul">{`Share variables between `}<inlineCode parentName="li">{`setup()`}</inlineCode>{` and `}<inlineCode parentName="li">{`loop()`}</inlineCode>{` by making them global variable. ⭐`}</li>
    </ul>
    <Chart1 mdxType="Chart1" />
    <hr></hr>
    <h1>{`Basic code organization`}</h1>
    <ul>
      <li parentName="ul">{`Seperate big trunks of code for different tasks into different functions.`}</li>
      <li parentName="ul">{`Use global variables for sharing data between functions. ⭐`}</li>
      <li parentName="ul">{`Define constants for hard-coded numbers used in the program, don't put them directly code, for easier tuning later.`}</li>
      <li parentName="ul">{`Log sensor values, program state and motor output for easier debugging.`}</li>
      <li parentName="ul">{`Write verbosive comments when you think you need it. More is better.`}</li>
    </ul>
    <hr></hr>
    <p>{`Seperating tasks`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`void readUltrasonicSensorValues(){
  // Read sensor values here
}

void readSerialInput(){
  // Read serial input here
}

void determineWhatToDo(){
  // Run program logic here.
}

void loop(){
  readUltrasonicSensorValues();
  readSerialInput();
  determineWhatToDo();
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Global variables and Constants`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`const float STOP_SENSOR_DISTANCE = 20.0;
const int MOTOR_PIN = A4;

float sensorDistance;
bool shouldContinue;
int motorSpeed;

void readUltrasonicSensorValues(){
  // processing
  sensorDistance = xxx;
}

void readSerialInput(){
  // processing
  shouldContinue = xxx;
}

void determineWhatToDo(){
  if (sensorDistance <= STOP_SENSOR_DISTANCE && shouldContinue){
    motorSpeed = 100;
  }else{
    motorSpeed = 0;
  }
}

void writeMotorSpeed(){
  analogWrite(MOTOR_PIN, motorSpeed);
}

void loop(){
  readUltrasonicSensorValues();
  readSerialInput();
  determineWhatToDo();
  writeMotorSpeed();
  writeLogToSerial();
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Program state and logging`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`const float STOP_SENSOR_DISTANCE = 20.0;
const float RUN_SENSOR_DISTANCE = 60.0;

float sensorDistance;
bool shouldContinue;
int motorSpeed;

ProgramState currentState;

enum class ProgramState : char { // Only integral type like int or char is allowed because of C++ limitations
  RUNNING = "R",
  MOVING = "M",
  TOO_CLOSE = "C",
  STOPPED_BY_SERIAL = "S"
};

void writeLogToSerial(){
  // Log current program state to serial input here.
  // Note that Serial output can block if the output buffer is full! Will introduce some techniques to deal with it later.
  // Fow now we only write if the buffer is not full. If the buffer is not full but still doesn't have enough space it still blocks.
  if (Serial.availableForWrite() > 0){
    Serial.print(',');
    Serial.print(motorSpeed);
    Serial.print(currentState);
    Serial.print(',');
    Serial.print(motorSpeed);
    Serial.print(',');
    Serial.print(sensorDistance);
    Serial.print(',');
    Serial.print(shouldContinue);
    Serial.println();
  }
}

void determineWhatToDo(){
  if (!shouldContinue){
    motorSpeed = 0;
    currentState = ProgramState::STOPPED_BY_SERIAL;
    return;
  }
  if (sensorDistance > RUN_SENSOR_DISTANCE){
    motorSpeed = 200;
    currentState = ProgramState::RUNNING;
    return;
  }
  if (sensorDistance > STOP_SENSOR_DISTANCE){
    motorSpeed = 100;
    currentSpeed = ProgramState::MOVING;
    return;
  }
  motorSpeed = 0;
  currentSpeed = ProgramState::TOO_CLOSE;
  return;
}


void loop(){
  readUltrasonicSensorValues();
  readSerialInput();
  determineWhatToDo();
  writeLogToSerial();
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Coding conventions`}</h1>
    <ul>
      <li parentName="ul">{`Document what you are doing with comments for each function (intentions) if it is not obvious.`}</li>
      <li parentName="ul">{`Don't repeat code (actual logic) with comments.`}</li>
      <li parentName="ul">{`Adapt a consistent naming style.`}</li>
      <li parentName="ul">{`Rember to put indentions properly. ⭐`}</li>
      <li parentName="ul">{`Use verbs for functions, and nouns for variables and classes.`}</li>
      <li parentName="ul">{`Give meaningful names, e.g. `}<inlineCode parentName="li">{`i`}</inlineCode>{` vs `}<inlineCode parentName="li">{`motorIndex`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Avoid using pointers (pass by reference instead). Arduino has very limited memories (8KB) so dynamic memory allocation can caused memory leakages. Also there are no OOM on Arduino, you won't know what broke your program. Hence prefer allocate on stack rather than heap. (There are also tools for analysing memory usages but obvously won't work with `}<inlineCode parentName="li">{`new`}</inlineCode>{` or `}<inlineCode parentName="li">{`malloc()`}</inlineCode>{` ⭐.).`}</li>
      <li parentName="ul">{`Use containers if you really need dynamic memory array like the built-in `}<inlineCode parentName="li">{`String`}</inlineCode>{` or `}<inlineCode parentName="li">{`Array`}</inlineCode>{`/`}<inlineCode parentName="li">{`Vector`}</inlineCode>{` libraries.`}</li>
      <li parentName="ul">{`Don't use exceptions (`}<inlineCode parentName="li">{`throw`}</inlineCode>{`) (there are no exceptions in Arduino anyway).`}</li>
      <li parentName="ul">{`You MAY want to use early return to code branches to achieve cleaner code. See the `}<inlineCode parentName="li">{`determineWhatToDo()`}</inlineCode>{` example above.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Suggested style guide`}</h2>
    <ul>
      <li parentName="ul">{`Adapted from `}<a parentName="li" {...{
          "href": "https://google.github.io/styleguide/cppguide.html#Variable_Names"
        }}>{`Google C++ style guide`}</a>{`.`}</li>
      <li parentName="ul">{`Modified to match existing conventions in the arduino ecosystem.`}</li>
      <li parentName="ul">{`You don't have to follow this, just make sure it is consistent across your group members' code.`}</li>
    </ul>
    <hr></hr>
    <h3>{`Formatting`}</h3>
    <ul>
      <li parentName="ul">{`2 spaces for each level.`}</li>
      <li parentName="ul">{`Don't use tabs.`}</li>
      <li parentName="ul">{`Each line should not exceed 80 chars.`}</li>
      <li parentName="ul">{`Use clang-format ⭐.`}</li>
      <li parentName="ul">{`Namespaces doesn't need indentions.`}</li>
    </ul>
    <hr></hr>
    <h3>{`Naming`}</h3>
    <ul>
      <li parentName="ul">{`Make sure the naming will be understandable even read by people not in your group.`}</li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Use CamelCase for class, structs and types`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`TimedState
BluetoothController
UrlParaser
I2cImpl
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Use camelCase for function names, the first word should be a verb ideally.`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`readUltrasonicSensorValues();
readSerialInput();
determineWhatToDo();
writeLogToSerial();
getNextInput();
setMotorSpeed();
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ul>
      <li parentName="ul">{`For functions parameters, global variables, function local variables and struct member variables, use snake_case.`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`float convertToFloat(int num){
  bool is_positive;
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ul>
      <li parentName="ul">{`For both static and instance class member variables, use snake`}<em parentName="li">{`case with underscore (snake_case`}</em>{`).`}</li>
      <li parentName="ul">{`Useful for differentiating between local variables and class member variables.`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`class TimedState : public State {
 protected:
  bool is_entered_ = false;

 public:
  TimedState(unsigned long period);
  void forceEnter() override;
};

TimedState::TimedState(unsigned long period) : period_(period) {}

void TimedState::forceEnter() {
  is_entered_ = true;
  timestamp_ = millis();
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Use snake_case for namespaces.`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`namespace timed_state;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Use capital letter with underscores for macros.`}</li>
      <li parentName="ul">{`Avoid use of macros.`}</li>
      <li parentName="ul">{`Some constants in the arduino ecosystem are defined using macros, follow macros conventions to name them if you have a reason to use one. However, you better use `}<inlineCode parentName="li">{`const`}</inlineCode>{` style constants since it provice better type checking.`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`#define ROUND(x) ...
#define PI_ROUNDED 3.0
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`SNAKE_CASE`}</inlineCode>{`, which is capital letter with underscores for constants.`}</li>
      <li parentName="ul">{`Some style guide wuggested otherwise, but I would have suggested using `}<inlineCode parentName="li">{`SNAKE_CASE`}</inlineCode>{` since this is the convention in the Arduino ecosystem.`}</li>
    </ul>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`const int BUTTON_PIN = 4;
const float distance = 40.0;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Some references`}</p>
    <p><a parentName="p" {...{
        "href": "https://llvm.org/docs/CodingStandards.html#name-types-functions-variables-and-enumerators-properly"
      }}>{`https://llvm.org/docs/CodingStandards.html#name-types-functions-variables-and-enumerators-properly`}</a>{`
`}<a parentName="p" {...{
        "href": "https://google.github.io/styleguide/cppguide.html#Variable_Names"
      }}>{`https://google.github.io/styleguide/cppguide.html#Variable_Names`}</a></p>
    <hr></hr>
    <h1>{`Sampling`}</h1>
    <ul>
      <li parentName="ul">{`Some sensors has pretty large error margins (e.g. ultrasonic sensors).`}</li>
      <li parentName="ul">{`Just one measurement may get you a wrong value.`}</li>
      <li parentName="ul">{`Solution: Take measurements from the sensor multiple times, sort the values, and then take the median.`}</li>
      <li parentName="ul">{`Let's use `}<a parentName="li" {...{
          "href": "https://www.nongnu.org/avr-libc/user-manual/group__avr__stdlib.html#gafd4bf2faec43342e7ad3d2ab37bac1fe"
        }}><inlineCode parentName="a">{`qsort()`}</inlineCode></a>{` to do it.`}</li>
    </ul>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`#define SAMPLE_SIZE 5
// Using macro-based constants here since we need this constant to define array size.

const int SONIC_ECHO_PIN = A10;
const int SONIC_TRIG_PIN = A11;

unsigned long measureSensorDurationOnce(){
  digitalWrite(SONIC_TRIG_PIN, LOW);
  delayMicroseconds(5);
  digitalWrite(SONIC_TRIG_PIN, HIGH);
  delayMicroseconds(10);
  digitalWrite(SONIC_TRIG_PIN, LOW);
  return pulseIn(SONIC_TRIG_PIN);
}

float mesaureSensorDistanceWithSampling(){
  unsigned long durations[SAMPLE_SIZE];
  for (int i = 0; i < SAMPLE_SIZE; i++){
    durations[i] = measureSensorDurationOnce();
  }
  qsort((void*)durations, SAMPLE_SIZE, sizeof(unsigned long), lessThanUul);
  unsigned long duration = durations[SAMPLE_SIZE / 2];
  return duration / 2.0 / 29.1;
}

int lessThanUul(const void* p1, const void* p2){
  unsigned long n1 = *((unsigned long*)p1);
  unsigned long n2 = *((unsigned long*)p2);
  if (n1 < n2){
    return -1;
  }
  if (n1 > n2){
    return 1;
  }
  return 0;
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Non-blocking control`}</h1>
    <hr></hr>
    <h2>{`What is delay()?`}</h2>
    <ul>
      <li parentName="ul">{`Stops the execution of the Arduino program for the specified period.`}</li>
      <li parentName="ul">{`No sensors can be measured.`}</li>
      <li parentName="ul">{`You can only "do one thing" with it.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Simple scenario`}</h2>
    <p>{`Blinks the LED in an one second interval if the button is pressed, otherwise turn the LED off.`}</p>
    <img src={assetUrl("assets/non-blocking-example-circuit.png")} />
    <hr></hr>
    <p>{`Let's try doing one thing only first.`}</p>
    <hr></hr>
    <h3>{`Button only`}</h3>
    <p>{`Turn the LED on if the button is pressed, otherwise turn it off.`}</p>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp",
          "metastring": "showNumbers",
          "showNumbers": true
        }}>{`const int BUTTON_PIN = 4;
const int LED_PIN = 13;

void setup() {
  Serial.begin(115200);
  pinMode(BUTTON_PIN, INPUT_PULLUP);
  pinMode(LED_PIN, OUTPUT);
}

void loop() {
  int buttonValue = digitalRead(BUTTON_PIN);
  digitalWrite(LED_PIN, !buttonValue);
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Result`}</p>
    <p><a parentName="p" {...{
        "href": "https://wokwi.com/arduino/projects/322041851103150676"
      }}>{`https://wokwi.com/arduino/projects/322041851103150676`}</a></p>
    <video src={assetUrl("assets/button-only.mp4")} controls autoPlay muted loop playsInline></video>
    <hr></hr>
    <h3>{`Blink only with delay`}</h3>
    <p>{`Just Blink the LED in an one second interval only, ignore button input.`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp",
          "metastring": "showNumbers",
          "showNumbers": true
        }}>{`const int BUTTON_PIN = 4;
const int LED_PIN = 13;

void setup() {
  Serial.begin(115200);
  pinMode(BUTTON_PIN, INPUT_PULLUP);
  pinMode(LED_PIN, OUTPUT);
}

void loop() {
  delay(1000);
  digitalWrite(LED_PIN, LOW);
  delay(1000);
  digitalWrite(LED_PIN, HIGH);
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Result`}</p>
    <p><a parentName="p" {...{
        "href": "https://wokwi.com/arduino/projects/322079425233420882"
      }}>{`https://wokwi.com/arduino/projects/322079425233420882`}</a></p>
    <video src={assetUrl("assets/blink-delay.mp4")} controls autoPlay muted loop playsInline></video>
    <hr></hr>
    <h3>{`Blink + Button with delay`}</h3>
    <ul>
      <li parentName="ul">{`Looks like we can do blinking with just `}<inlineCode parentName="li">{`delay()`}</inlineCode>{` right?`}</li>
      <li parentName="ul">{`But can we use button to control it if we use `}<inlineCode parentName="li">{`delay()`}</inlineCode>{` only?`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`delay()`}</inlineCode>{` is blocking, which means the board will no longer sensetive to changes in sensor input when it is waiting for `}<inlineCode parentName="li">{`delay()`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`How would this "blocking" thing affect our implementation?`}</li>
    </ul>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp",
          "metastring": "showNumbers",
          "showNumbers": true
        }}>{`const int BUTTON_PIN = 4;
const int LED_PIN = 13;

void setup() {
  Serial.begin(115200);
  pinMode(BUTTON_PIN, INPUT_PULLUP);
  pinMode(LED_PIN, OUTPUT);
}

void loop() {
  int buttonPressed = !digitalRead(BUTTON_PIN);
  delay(1000);
  digitalWrite(LED_PIN, LOW);
  if (buttonPressed) {
    delay(1000);
    digitalWrite(LED_PIN, HIGH);
  }
}

`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Result`}</p>
    <p><a parentName="p" {...{
        "href": "https://wokwi.com/arduino/projects/322081256635368020"
      }}>{`https://wokwi.com/arduino/projects/322081256635368020`}</a></p>
    <video src={assetUrl("assets/blink-and-button-delay.mp4")} controls autoPlay muted loop playsInline></video>
    <hr></hr>
    <p>{`Observations`}</p>
    <ul>
      <li parentName="ul">{`When the button starts being held, the LED starts blinking after a small delay.`}</li>
      <li parentName="ul">{`When the button is being held, the LED blinks contiuously.`}</li>
      <li parentName="ul">{`When the button is released, the LED stills blinks for the last cycle.`}</li>
      <li parentName="ul">{`We need the LED to stop blinking when it is turned off. The current behavior is not desirable.`}</li>
    </ul>
    <hr></hr>
    <p>{`Analysis`}</p>
    <ul>
      <li parentName="ul">{`When the `}<inlineCode parentName="li">{`delay(1000)`}</inlineCode>{` function in `}<inlineCode parentName="li">{`L12`}</inlineCode>{` and `}<inlineCode parentName="li">{`L15`}</inlineCode>{` is called, the program pause without doing anything for 1 second, hence the sensor input (button) will not be read and changes will not be reflected.`}</li>
      <li parentName="ul">{`If the button is pressed during `}<inlineCode parentName="li">{`delay()`}</inlineCode>{` in `}<inlineCode parentName="li">{`L12`}</inlineCode>{`, then the `}<inlineCode parentName="li">{`L13`}</inlineCode>{` that turns off the LED will still be executed, hence the `}<inlineCode parentName="li">{`L14`}</inlineCode>{` would still be false and the `}<inlineCode parentName="li">{`loop()`}</inlineCode>{` function would return, in which the `}<inlineCode parentName="li">{`loop()`}</inlineCode>{` function would be reentered again, `}<inlineCode parentName="li">{`L14`}</inlineCode>{` would be `}<inlineCode parentName="li">{`true`}</inlineCode>{`, and the LED will be turned on after two seconds of delay.`}</li>
      <li parentName="ul">{`If the button is released during `}<inlineCode parentName="li">{`delay()`}</inlineCode>{` in `}<inlineCode parentName="li">{`L15`}</inlineCode>{`, then `}<inlineCode parentName="li">{`L16`}</inlineCode>{` would still be executed, hence the LED light is turned on, then the `}<inlineCode parentName="li">{`loop()`}</inlineCode>{` function will be executed again and the LED light will be turned off after another 1 second.`}</li>
      <li parentName="ul">{`A graph will be provided after to provide you with a better illustraion if you hate text.`}</li>
    </ul>
    <hr></hr>
    <p>{`Program flowchart`}</p>
    <Chart2 mdxType="Chart2" />
    <p>{`Program flow when the button was initally released, and started being pressed during the first `}<inlineCode parentName="p">{`delay(1000)`}</inlineCode></p>
    <hr></hr>
    <h3>{`Blink + Button with millis()`}</h3>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`
const byte button=4;
const byte LED=13;

unsigned long Interval = 1000;
unsigned long currentMillis;

void setup() {
 pinMode(button, INPUT_PULLUP);
 pinMode(LED, OUTPUT);
}

void loop() {
 if (digitalRead(button) == LOW  ){
     currentMillis = millis();
     if ((currentMillis/Interval) %2 != 0  ) {
       digitalWrite(LED, HIGH);
       }
      else{
         digitalWrite(LED, LOW);
      }

 }

 if (digitalRead(button)==HIGH){
   digitalWrite(LED, LOW);

 }

}


`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Result`}</p>
    <p><a parentName="p" {...{
        "href": "https://wokwi.com/projects/327387810779628116"
      }}>{`https://wokwi.com/projects/327387810779628116`}</a></p>
    <hr></hr>
    <h3>{`Blink + Button with TimedState`}</h3>
    <hr></hr>
    <p>{`The previous code with millis would be a little bit hard to read,
so let's use a wrapper library for better logic.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`TimedState`}</inlineCode>{` from your Arduino IDE or Wokwi simulator.`}</p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-cpp"
        }}>{`
#include <TimedState.h>

const int BUTTON_PIN = 4;
const int LED_PIN = 13;

RepeatingTimedState led_should_blink(1000, 1000, true);

void setup() {
  Serial.begin(115200);
  pinMode(BUTTON_PIN, INPUT_PULLUP);
  pinMode(LED_PIN, OUTPUT);
  led_should_blink.enter();
}

void loop() {
  int buttonPressed = !digitalRead(BUTTON_PIN);
  if (buttonPressed && led_should_blink.isInside()){
    digitalWrite(LED_PIN, HIGH);
  } else {
    digitalWrite(LED_PIN, LOW);
  }
}


`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`Result`}</p>
    <p><a parentName="p" {...{
        "href": "https://wokwi.com/projects/327109378634154579"
      }}>{`https://wokwi.com/projects/327109378634154579`}</a></p>
    <p>{`Much cleaner code with the timing logic being battle tested already.`}</p>
    <hr></hr>
    <p>{`Hope you guys can benefit from this note!`}</p>
    <p>{`Feel free to ask me if you have questions by joining this telegram group:
`}<a parentName="p" {...{
        "href": "https://t.me/+497_nk6Uj7RmM2U1"
      }}>{`https://t.me/+497_nk6Uj7RmM2U1`}</a></p>
    <hr></hr>
    <p>{`THE END`}</p>
    <p>{`Special thanks to Dora writing the final parts.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;